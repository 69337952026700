'use strict'

exports.getSearchKey = function(url) {
  if (!url) {
    return ''
  }
  let eg = []
  eg.push(['baidu', 'wd'])
  eg.push(['google', 'q'])
  eg.push(['soso', 'w'])
  eg.push(['yodao', 'q'])
  eg.push(['bing', 'q'])
  eg.push(['yahoo', 'q'])
  eg.push(['sogou', 'query'])
  eg.push(['gougou', 'search'])
  eg.push(['360', 'q'])

  let dq = getDomainQuery(url)

  let keyword = null
  let grep = null
  let str = null
  let sb = ''

  for (var el in eg) {
    var s = eg[el]
    var DandQ = String(s).split(',') //字符分割
    if (dq[0].indexOf(DandQ[0]) > 0) {
      eval('grep=/' + DandQ[1] + '=.*&/i;')
      str = dq[1].match(grep)
      keyword = str
        .toString()
        .split('=')[1]
        .split('&')[0]
      sb += decodeURIComponent(keyword)
    }
  }
  return sb
}

function getDomainQuery(url) {
  var d = []
  var st = url.indexOf('//', 1)
  var _domain = url.substring(st + 1, url.length)
  var et = _domain.indexOf('/', 1)
  d.push(_domain.substring(1, et))
  d.push(_domain.substring(et + 1, url.length))
  return d
}
