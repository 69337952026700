<template>
  <div class="header">
    <div class="top">
      <div class="right">
        <span v-if="user">
          欢迎 {{ user }}
          <button type="text" @click="logout">注销</button>
        </span>
        <router-link :to="'/login'" v-else>登录</router-link>
      </div>
      <div><a href="/">趣名网，有趣的事情都在这里</a></div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { useHead } from '@vueuse/head'
import { computed } from 'vue'

export default {
  name: 'Header',
  data() {
    return {}
  },

  created() {
    this.$store.dispatch('getUser')

    useHead({
      title: computed(() => this.post.title),
      meta: [
        {
          property: 'description',
          content: computed(() => this.post.title),
        },
      ],
    })
  },
  computed: {
    ...mapState(['user', 'post']),
  },
  methods: {
    logout() {
      console.log('logout')
      this.$store.dispatch('FedLogOut').then(() => {
        location.reload() // In order to re-instantiate the vue-router object to avoid bugs
      })
    },
  },
}
</script>

<style>
.header {
  background: #111;
  color: white;
  padding: 5px;
  margin: 0px auto;
}
.right {
  float: right;
}
.header a {
  color: white;
}
.top {
  max-width: 1200px;
  margin: 1px auto;
}
</style>
