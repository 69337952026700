<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
body {
  margin: 0px;
}
a:link {
  text-decoration: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0px;
}

.container {
  max-width: 1200px;
  margin: 0px auto;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'top-left top-middle top-right'
    'bottom bottom bottom';
}

.container1 {
  max-width: 1200px;
  margin: 0px auto;
}

.item-a {
  grid-area: top-left;
}

.item-b {
  grid-area: top-middle;
}
.item-c {
  grid-area: top-right;
}
.item-d {
  grid-area: bottom;
}
</style>
