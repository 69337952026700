import { createStore } from 'vuex'
import axios from 'axios'
import md5 from 'js-md5'

const api = axios.create({
  baseURL: 'https://fgz.cn'
})

const QUOTA_EXCEEDED_ERR_CODE = 22
function write(key, data) {
  try {
    localStorage.setItem(key, data)
  } catch (e) {
    if (e.code === QUOTA_EXCEEDED_ERR_CODE) {
      localStorage.clear()
      localStorage.setItem(key, data)
    }
  }
}

async function sget(url) {
  let date = new Date()
  let localKey = 'p' + Math.floor(date.getTime() / 200000) + md5(url)
  let data = JSON.parse(localStorage.getItem(localKey))
  if (!data) {
    let tmp = await api.get(url)
    data = tmp.data
    write(localKey, JSON.stringify(data))
  }
  return data
}

export default createStore({
  state: {
    items: [],
    boards: [],
    posts: [],
    post: '',
    photos: [],
    user: '',
    site: '',
    goods: []
  },
  mutations: {
    loadItems(state, item) {
      state.items.push(item)
    },
    clearItems(state) {
      state.items = []
    },
    storeUser(state, item) {
      state.user = item
    },
    storeSite(state, item) {
      state.site = item
    },
    loadBoards(state, item) {
      state.boards = item
    },
    loadPosts(state, item) {
      state.posts.push(item)
    },
    clearPosts(state) {
      state.posts = []
    },
    loadPhotos(state, item) {
      state.photos.push(item)
    },
    clearPhotos(state) {
      state.photos = []
    },
    loadPost(state, item) {
      state.post = item
    },
    loadGoods(state, item) {
      if (item.goods_search_response && item.goods_search_response.goods_list) {
        state.goods = item.goods_search_response.goods_list
      }
    }
  },
  actions: {
    fetchItems(context, type = 'top') {
      context.commit('clearItems')
      api
        .get(`${type}stories.json?limitToFirst=20&orderBy="$key"`)
        .then(res => {
          res.data.forEach(id => {
            api.get(`item/${id}.json?print=pretty`).then(res => {
              context.commit('loadItems', res.data)
            })
          })
        })
        .catch(error => {
          console.error(error)
        })
    },
    getBoards(context) {
      api.get('/api/v1/boards').then(res => {
        if (res.success === true) {
          context.commit('loadBoards', res.data)
        }
      })
    },
    async getPosts(context, key) {
      context.commit('clearPosts')
      let res = await sget('/api/v1/posts/lucky?key=' + key)
      if (res.success === true) {
        res.data.forEach(p => {
          context.commit('loadPosts', p)
        })
      }
    },
    async getPost(context, id) {
      let res = await sget('/api/v1/posts/' + id)

      context.commit('loadPost', res.data)
      return res.data
    },

    async getPhotos(context, params) {
      let res = await api.get('/api/v1/photos?limit=3&board=' + params.board + '&offset=' + params.offset)

      if (res.success === true) {
        res.data.forEach(p => {
          context.commit('loadPhotos', p)
        })
      }
    },

    async getGoods(context, params) {
      let url = '/api/v1/pdd?'
      for (let i in params) {
        url += '&' + i + '=' + params[i]
      }
      let res = await sget(url)
      if (res.success === true) {
        context.commit('loadGoods', res.data)
      }
    },

    getUser(context) {
      api.get('/auth/me').then(res => {
        if (res.success === true) {
          context.commit('storeUser', res.data)
        }
      })
    },
    getSite(context) {
      api.get('/api/v1/sites').then(res => {
        if (res.success === true) {
          context.commit('storeSite', res.data)
        }
      })
    }
  }
})
