import Home from './pages/Home.vue'
import Show from './pages/Show.vue'
import NotFound from './pages/404.vue'

/** @type {import('vue-router').RouterOptions['routes']} */
export let routes = [
  { path: '/', component: Home, meta: { title: 'Home' } },
  { path: '/show/:ID', component: Show, meta: { title: 'Show' } },
  { path: '/:path(.*)', component: NotFound }
]
