<template>
  <div class="footer">
    
      <p>@2019 版权所有 {{ site.domain }}</p>
      <p>
        <a> 趣名网 </a>
        提供托管服务
      </p>
      
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Footer',
  data() {
    return {
      host: {},
    }
  },
  computed: {
    ...mapState(['site']),
  },
  async created() {},
}
</script>

<style>
.footer {
  margin: 10px auto;
  text-align: center;
  font-size: 0.6em;
  color: gray;
}
.footer img {
  vertical-align: middle;
}
</style>
