<template>
  <div>
    <div id="xcx" v-if="showxcx" @click="hideXcx()">
      <div class="content">
        <img src="https://aliyun-file-2019.oss-cn-beijing.aliyuncs.com/airdrop.jpg" alt="" style="width: 99%" />
        <p>扫码获得优惠券</p>
      </div>
    </div>
    <p>
      <a
        v-for="c in categorys"
        :key="c.oid"
        style="border: 1px solid red; background: darkred; color: white; margin: 2px"
        href="#"
        @click="loadGoods(c.oid)"
        >{{ c.text }}</a
      >
    </p>
    <div v-for="g in goods" :key="g.id" style="border-bottom: 1px solid grey">
      <table>
        <tr>
          <td>
            <img :src="g.goods_thumbnail_url" style="width: 120px" />
          </td>
          <td>
            <a @click="showXcx()">
              <p>{{ g.goods_name }}</p>
            </a>
            <p>品类：{{ g.category_name }}</p>
            <p>店铺：{{ g.mall_name }}</p>

            <p>
              <span style=""
                >券后价：<span style="text-decoration: line-through; color: green">{{ g.min_normal_price / 100 }} 元</span
                ><span style="color: red; font-size: 120%"> {{ g.min_group_price / 100 }} 元</span>
              </span>
            </p>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'App',

  data() {
    return {
      offset: 0,
      oid: 8730,
      showxcx: false,
      categorys: [
        {
          text: '精选',
          oid: 8569,
        },
        {
          text: '百货',
          oid: 15,
        },
        {
          text: '母婴',
          oid: 4,
        },
        {
          text: '食品',
          oid: 1,
        },
        {
          text: '女装',
          oid: 14,
        },
        {
          text: '电器',
          oid: 18,
        },
        {
          text: '鞋包',
          oid: 1281,
        },
        {
          text: '内衣',
          oid: 1282,
        },
        {
          text: '美妆',
          oid: 16,
        },
        {
          text: '男装',
          oid: 743,
        },
        {
          text: '水果',
          oid: 13,
        },
        {
          text: '家纺',
          oid: 818,
        },
        {
          text: '文具',
          oid: 2478,
        },
        {
          text: '运动',
          oid: 1451,
        },
        {
          text: '汽车',
          oid: 2048,
        },
        {
          text: '家装',
          oid: 1917,
        },
        {
          text: '家具',
          oid: 2974,
        },
        {
          text: '医药',
          oid: 3279,
        },
      ],
    }
  },
  components: {},
  created() {
    this.$store.dispatch('getGoods', { opt_id: this.oid })
  },
  computed: {
    ...mapState(['goods']),
  },
  methods: {
    loadGoods(oid) {
      this.oid = oid
      this.$store.dispatch('getGoods', { opt_id: oid })
    },
    showXcx() {
      this.showxcx = true
    },
    hideXcx() {
      this.showxcx = false
    },
  },
}
</script>

<style>
#xcx {
  z-index: 996;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #333;
}

#xcx .content {
  z-index: 999;
  position: absolute;
  top: 30%;
  left: 40%;
  max-width: 300px;
  text-align: center;
  margin: 0px auto;
  border: 20px solid yellowgreen;
  border-radius: 10%;
  color: white;
}
#xcx .content img {
  border-radius: 50%;
}
</style>
