<template>
  <Header></Header>

  <div class="container">
    <div class="item-a board">
      <Leftbar></Leftbar>
    </div>

    <div class="item-b">
      <a :href="post.url" target="_blank">
        <h2>{{ post.title }}</h2>
      </a>
      <p>{{ post.user }} {{ post.createdAt }}</p>

      <div class="post_con" v-html="post.content"></div>
      <div v-html="post.url"></div>
      <div v-if="post.img">
        <img :src="post.img" />
      </div>
      <div data-chirpy-comment="true"></div>
    </div>
    <div class="item-b">
      <Photobar></Photobar>
    </div>
  </div>

  <Footer></Footer>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import Leftbar from '../components/Leftbar.vue'
import Photobar from '../components/Photobar.vue'
import { mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Leftbar,
    Photobar,
  },
  created() {
    this.$store.dispatch('getPost', this.$route.params.ID)
  },
  computed: {
    ...mapState(['post']),
  },
}
</script>

<style>
</style>
