import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import VueGtag from 'vue-gtag-next'

import { createHead } from '@vueuse/head'

import { routes } from './routes.js'
import { createRouter, createWebHistory } from 'vue-router'

const app = createApp(App)
app.config.devtools = true
app.use(store)
let router = createRouter({
  history: createWebHistory(),
  routes: routes
})

app.use(router)

app.use(VueGtag, {
  property: {
    id: 'UA-54302184-1'
  }
})

const head = createHead()
app.use(head)
app.mount('#app')
