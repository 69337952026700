<template>
  <Header></Header>

  <div class="container">
    <div class="item-a board">
      <Leftbar></Leftbar>
    </div>

    <div class="item-b content">
      <div class="post" v-for="n in posts" :key="n.id">
        <p>
          <router-link :to="'/show/' + n.id" style="font-weight: bold" target="_blank">{{ n.title }}</router-link>
        </p>
        <p>
          <span>[{{ n.board }}]</span> &nbsp; <span style="color: #800000">{{ n.userId }}</span
          >&nbsp;
          <span style="color: grey; font-size: 0.8em">{{ n.createdAt }}</span>
        </p>
        <img :src="n.img" alt="" v-if="n.img" style="width: 80px" />
      </div>
    </div>
    <div class="item-c goods">
      <Pddbar></Pddbar>
    </div>
  </div>

  <Footer></Footer>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import Leftbar from '../components/Leftbar.vue'
import Pddbar from '../components/Pddbar.vue'

import { mapState } from 'vuex'
import { getSearchKey } from '../utils'
export default {
  name: 'App',

  data() {
    return {}
  },
  components: {
    Header,
    Footer,
    Leftbar,
    Pddbar,
  },
  created() {
    // 获得关键词
    let domain = document.domain
    let key = getSearchKey(document.referrer)
    console.log(key, window.referer, 'key', domain)
    // let skip = ['zhengshuang.cc', 'www.zhengshuang.cc', 'vipkid.cc']

    this.$store.dispatch('getPosts', key)
    this.$store.dispatch('getPhotos', key)
  },
  computed: {
    ...mapState(['posts', 'photos']),
  },
  methods: {},
}
</script>

<style>
.board {
  /* border: 1px solid grey; */
  width: 200px;
  padding: 20px;
}
.board .item {
  border: 1px solid rgb(202, 200, 200);
  background: rgb(215, 219, 219);
  padding: 10px;
  margin: 1px;
  border-radius: 5px;
  text-shadow: #333;
}
.board .item a {
  color: #333;
  text-decoration: none;
}
.content {
  padding: 20px;
}

.post {
  border-bottom: 1px solid #dee;
}
.post a {
  color: #333;
  text-decoration: none;
}
.goods {
  width: 400px;
}
</style>
