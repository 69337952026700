<template>
  <div>
    <div v-for="g in photos" :key="g.id" style="border-bottom: 1px solid grey">
      <table>
        <tr>
          <td>
            <img :src="g.goods_thumbnail_url" style="width: 120px" />
          </td>
          <td>
            <a>
              <p>{{ g.goods_name }}</p>
            </a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'App',

  data() {
    return {}
  },
  components: {},
  created() {
    this.$store.dispatch('getPhotos', { board: '儿童教育(ChildEducation)', limit: 5 })
  },
  computed: {
    ...mapState(['photos']),
  },
  methods: {},
}
</script>

<style>
#xcx {
  z-index: 996;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #333;
}

#xcx .content {
  z-index: 999;
  position: absolute;
  top: 30%;
  left: 40%;
  max-width: 300px;
  text-align: center;
  margin: 0px auto;
  border: 20px solid yellowgreen;
  border-radius: 10%;
  color: white;
}
#xcx .content img {
  border-radius: 50%;
}
</style>
